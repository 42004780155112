export const NUMBER_OF_BALLS_FOR_DESKTOP = 7;
export const NUMBER_OF_BALLS_FOR_MOBILE = 5;
export const BALL_RADIUS_ON_DESKTOP = 25;
export const BALL_RADIUS_ON_MOBILE = 20;
export const MINIMUM_DISTANCE_FROM_EDGE = 30;
export const MAXIMUM_SPEED_IN_PX_PER_SECOND_ON_DESKTOP = 300;
export const MAXIMUM_SPEED_IN_PX_PER_SECOND_ON_MOBILE = 180;
export const DRAWING_TO_DISPLAY_SCALE_RATIO = 4;
export const PROFILE_PICTURES = [
  'anton.png',
  'brandon.png',
  'caroline.png',
  'erin.png',
  'haley.png',
  'jackie.png',
  'jazmin.png',
  'lily.png',
  'marissa.png',
  'matt.png',
  'milo.png',
  'sam.png',
  'vienna.png',
  'zach.png'];
