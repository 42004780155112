import {nFormatter} from '../plugins/nFormatter';
import {loadsvg} from '../plugins/loadsvg';

export const loadProfileCard = () => {
  loadsvg();

  // Check if element is in vieport
  $.fn.isInViewport = function isInViewport() {
    const elementTop = $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return (elementBottom > viewportTop && elementTop < viewportBottom);
  };

  // Add Data from Json to each component
  function influencerCardComponent() {
    const target = $('[data-influencer]');
    const profilePicture = $('.ss-ic-profile-pic');
    const contact = $('.ss-influencer-link');
    const handle = $('.ss-influencer-handle');
    const followers = $('.ss-ic-followers');
    const location = $('.ss-ic-location');
    const avgLikesComments = $('.ss-ic-av-likes-comments');
    const avgDailyPosts = $('.ss-ic-daily-posting-av');
    const engagementRate = $('.ss-ic-engagement-rate');
    const growthRate = $('.ss-ic-wow-growth');
    let targetHandle;

    $(target).each(function loopJson() {
      if ($(this).hasClass('done')) {
        // Do nothing
      } else if ($(this).isInViewport()) {
        const targetElement = $(this);
        targetElement.addClass('done').removeClass('loading');
        targetHandle = targetElement.data('influencer');

        /* get accounts via JSON */
        $.getJSON(`https://app.socialstudies.io/api/v1/data_feed/influencers/${targetHandle}`, (data) => {
          const jsonData = data.influencer;
          const jPicture = jsonData.profile_picture_url;
          const jHandle = `<span>@</span>${jsonData.instagram_account}`;
          const instaUrl = jsonData.instagram_profile_url;
          const jLocation = jsonData.location_name;
          const jFollowers = nFormatter(jsonData.followers_count, 1);
          const avgLikes = nFormatter(jsonData.avg_likes, 1);
          const avgComments = nFormatter(jsonData.avg_comments, 1);
          const jAvgDailyPosts = nFormatter(jsonData.avg_daily_posts, 2);
          const jEngagementRate = `${nFormatter(100 * jsonData.engagement_rate, 2)}%`;
          const jGrowthRate = `${nFormatter(100 * jsonData.growth_rate, 2)}%`;

          // Create Image and Append it
          function createImage() {
            const img = new Image();
            img.classList.add('image');
            targetElement.find(profilePicture).append(img).removeClass('loading').addClass('loaded');
            img.setAttribute('src', jPicture);
          }
          createImage();

          // Influencer Handle
          targetElement.find(handle).append(jHandle)
            .attr('href', instaUrl).removeClass('loading')
            .addClass('loaded');
          targetElement.find('.ss-influencer-info').removeClass('loading').addClass('loaded');

          // Link to Instagram URL
          if (instaUrl != null) {
            targetElement.find(contact)
              .addClass('loaded')
              .html(`<a href="${instaUrl}" target="_blank">Follow</a><svg class="ss-icon"><use xlink:href="#arrow"></use></svg>`);
          } else {
            targetElement.find(contact).parent().remove();
          }

          // Check for Location
          if (jLocation != null) {
            targetElement.find(location).append(jLocation).removeClass('loading').addClass('loaded');
          } else {
            targetElement.find(location).parent().remove();
          }

          // Check for Followers
          if (jFollowers != null) {
            targetElement.find(followers).append(jFollowers).removeClass('loading').addClass('loaded');
          } else {
            targetElement.find(followers).parent().remove();
          }

          // Check for Average Likes & Comments
          if (avgLikes && avgComments != null) {
            targetElement.find(avgLikesComments)
              .append(`${avgLikes} / ${avgComments}`)
              .removeClass('loading').addClass('loaded');
          } else if (avgLikes != null && avgComments === null) {
            targetElement.find(avgLikesComments)
              .append(`${avgLikes} / N/A`)
              .removeClass('loading')
              .addClass('loaded');
          } else if (avgLikes === null && avgComments != null) {
            targetElement.find(avgLikesComments)
              .append(`N/A / ${avgComments}`)
              .removeClass('loading')
              .addClass('loaded');
          } else {
            targetElement.find(avgLikesComments).parent().remove();
          }

          // Check for Average Daily Posts
          if (jAvgDailyPosts != null) {
            targetElement.find(avgDailyPosts)
              .append(jAvgDailyPosts)
              .removeClass('loading')
              .addClass('loaded');
          } else {
            targetElement.find(avgDailyPosts).parent().remove();
          }

          // Check for 30 Days Growth Rate
          if (jEngagementRate != null) {
            targetElement.find(engagementRate)
              .append(jEngagementRate)
              .removeClass('loading')
              .addClass('loaded');
          } else {
            targetElement.find(engagementRate).parent().remove();
          }

          // Check for W/O/W Growth
          if (jGrowthRate != null) {
            targetElement.find(growthRate)
              .append(jGrowthRate)
              .removeClass('loading')
              .addClass('loaded');
          } else {
            targetElement.find(growthRate).parent().remove();
          }
        }).fail(() => {
          targetElement.remove();
        });
      }
    });
  }

  // Run Influencer Card Component function on scroll or resize
  $(window).on('load resize scroll', () => {
    $('[data-influencer]').each(function looptarget() {
      if ($(this).hasClass('done')) {
        // Do nothing
      } else {
        influencerCardComponent();
      }
    });
  });
};
