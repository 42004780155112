import {
  NUMBER_OF_BALLS_FOR_DESKTOP,
  BALL_RADIUS_ON_DESKTOP,
  BALL_RADIUS_ON_MOBILE,
  MINIMUM_DISTANCE_FROM_EDGE,
  MAXIMUM_SPEED_IN_PX_PER_SECOND_ON_DESKTOP,
  MAXIMUM_SPEED_IN_PX_PER_SECOND_ON_MOBILE,
  DRAWING_TO_DISPLAY_SCALE_RATIO,
  PROFILE_PICTURES,
  NUMBER_OF_BALLS_FOR_MOBILE
} from './constants';
import {MOBILE_WIDTH_BREAKPOINT} from '../../constants';

export const loadTeamMembers = (filePathToImages) => {
  const canvas = document.getElementById('teamMembersCanvas');
  const canvasContext = canvas.getContext('2d');
  const balls = [];
  let lastTimestamp = null;
  let secondsPassed = 0;

  const clearCanvas = () => {
    canvasContext.clearRect(0, 0, canvas.width, canvas.height);
  };

  const canvasBackground = () => {
    canvas.style.backgroundColor = 'rgb(0, 0, 0)';
  };

  const getCanvasDimension = (dimensionType) => {
    if (dimensionType === 'X') {
      return canvas.width;
    }
    return canvas.height;
  };

  const getMaxSpeedForDeviceSize = () => {
    if (document.documentElement.clientWidth < MOBILE_WIDTH_BREAKPOINT) {
      return MAXIMUM_SPEED_IN_PX_PER_SECOND_ON_MOBILE * DRAWING_TO_DISPLAY_SCALE_RATIO;
    }
    return MAXIMUM_SPEED_IN_PX_PER_SECOND_ON_DESKTOP * DRAWING_TO_DISPLAY_SCALE_RATIO;
  };

  const randomSpeed = () => (Math.floor(Math.random() * getMaxSpeedForDeviceSize()));

  const randomPosition = (dimensionType) => {
    const canvasSize = getCanvasDimension(dimensionType);
    let pos = Math.floor(Math.random() * canvasSize);
    if (pos < MINIMUM_DISTANCE_FROM_EDGE) {
      pos = MINIMUM_DISTANCE_FROM_EDGE;
    } else if (pos > canvasSize - MINIMUM_DISTANCE_FROM_EDGE) {
      pos = canvasSize - MINIMUM_DISTANCE_FROM_EDGE;
    }
    return pos;
  };

  const randomImage = () => {
    const usedImages = [];
    balls.forEach(ball => usedImages.push(
      ball.currentImage
    ));
    const availableImages = PROFILE_PICTURES.filter(el => !usedImages.includes(el));
    if (availableImages.length < 1) {
      throw Error('There are too many balls required and not enough images to supply them with.');
    }
    return availableImages[Math.floor(Math.random() * availableImages.length)];
  };

  class Ball {
    constructor(x, y, radius) {
      this.radius = radius;
      this.dx = randomSpeed();
      this.dy = randomSpeed();
      this.mass = radius ** 3;
      this.x = x;
      this.y = y;
      this.profileImage = document.createElement('img');
      this.setRandomImage();
    }

    setRandomImage() {
      this.currentImage = randomImage();
      this.profileImage.src = filePathToImages + this.currentImage;
    }

    setRadius(radius) {
      this.radius = radius;
    }

    setSpeed(speed) {
      this.dx = speed;
      this.dy = speed;
    }

    draw() {
      canvasContext.drawImage(this.profileImage, this.x - this.radius, this.y - this.radius,
        this.radius * 2, this.radius * 2);
    }

    move(duration) {
      if (Number.isNaN(duration)) {
        duration = 0;
      }
      secondsPassed = duration / 1000;
      this.x += (this.dx * secondsPassed);
      this.y += (this.dy * secondsPassed);
    }

    expectedNextXPosition() {
      return this.x + this.dx * secondsPassed;
    }

    expectedNextYPosition() {
      return this.y + this.dy * secondsPassed;
    }

    distanceToOtherBallInNextFrame(ball2) {
      const xDistance = this.expectedNextXPosition() - ball2.expectedNextXPosition();
      const yDistance = this.expectedNextYPosition() - ball2.expectedNextYPosition();
      return Math.sqrt((xDistance ** 2) + (yDistance ** 2)) - this.radius - ball2.radius;
    }

    distanceToOtherBallCenterNow(ball2) {
      const xDistance = this.x - ball2.x;
      const yDistance = this.y - ball2.y;
      return Math.sqrt((xDistance ** 2) + (yDistance ** 2));
    }

    checkForWallCollision() {
      if (this.expectedNextXPosition() - this.radius < 0
        || this.expectedNextXPosition() + this.radius > canvas.width) {
        this.dx *= -1;
        // this.setRandomImage();
      }
      if (this.expectedNextYPosition() - this.radius < 0
        || this.expectedNextYPosition() + this.radius > canvas.height) {
        this.dy *= -1;
        // this.setRandomImage();
      }
      if (this.y + this.radius > canvas.height) {
        this.y = canvas.height - this.radius;
      }
      if (this.y - this.radius < 0) {
        this.y = this.radius;
      }
      if (this.x + this.radius > canvas.width) {
        this.x = canvas.width - this.radius;
      }
      if (this.x - this.radius < 0) {
        this.x = this.radius;
      }
    }

    checkForImpendingCollisionWithOtherBall(ball2) {
      if (this !== ball2 && this.distanceToOtherBallInNextFrame(ball2) <= 0) {
        const theta1 = this.angle();
        const theta2 = ball2.angle();
        const phi = Math.atan2(ball2.y - this.y, ball2.x - this.x);
        const m1 = this.mass;
        const m2 = ball2.mass;
        const v1 = this.speed();
        const v2 = ball2.speed();
        const dx1F = (v1 * Math.cos(theta1 - phi) * (m1 - m2) + 2 * m2 * v2 * Math.cos(theta2 - phi)) / (m1 + m2)
          * Math.cos(phi) + v1 * Math.sin(theta1 - phi) * Math.cos(phi + Math.PI / 2);
        const dy1F = (v1 * Math.cos(theta1 - phi) * (m1 - m2) + 2 * m2 * v2 * Math.cos(theta2 - phi)) / (m1 + m2)
          * Math.sin(phi) + v1 * Math.sin(theta1 - phi) * Math.sin(phi + Math.PI / 2);
        const dx2F = (v2 * Math.cos(theta2 - phi) * (m2 - m1) + 2 * m1 * v1 * Math.cos(theta1 - phi)) / (m1 + m2)
          * Math.cos(phi) + v2 * Math.sin(theta2 - phi) * Math.cos(phi + Math.PI / 2);
        const dy2F = (v2 * Math.cos(theta2 - phi) * (m2 - m1) + 2 * m1 * v1 * Math.cos(theta1 - phi)) / (m1 + m2)
          * Math.sin(phi) + v2 * Math.sin(theta2 - phi) * Math.sin(phi + Math.PI / 2);
        this.dx = dx1F;
        this.dy = dy1F;
        ball2.dx = dx2F;
        ball2.dy = dy2F;
      }
    }

    checkForBallOverlap(ball2) {
      if (this !== ball2 && this.distanceToOtherBallCenterNow(ball2) < this.radius + ball2.radius) {
        const theta = Math.atan2((this.y - ball2.y), (this.x - ball2.x));
        const overlap = this.radius + ball2.radius - this.distanceToOtherBallCenterNow(ball2);
        this.x -= overlap * Math.cos(theta);
        this.y -= overlap * Math.sin(theta);
      }
    }

    speed() {
      return Math.sqrt(this.dx * this.dx + this.dy * this.dy);
    }

    angle() {
      return Math.atan2(this.dy, this.dx);
    }
  }

  const getNumberOfBallsForDeviceSize = () => {
    if (document.documentElement.clientWidth < MOBILE_WIDTH_BREAKPOINT) {
      return NUMBER_OF_BALLS_FOR_MOBILE;
    }
    return NUMBER_OF_BALLS_FOR_DESKTOP;
  };

  const getBallRadiusForDeviceSize = () => {
    if (document.documentElement.clientWidth < MOBILE_WIDTH_BREAKPOINT) {
      return BALL_RADIUS_ON_MOBILE * DRAWING_TO_DISPLAY_SCALE_RATIO;
    }
    return BALL_RADIUS_ON_DESKTOP * DRAWING_TO_DISPLAY_SCALE_RATIO;
  };

  const createRequiredNumberOfBalls = () => {
    const numberOfBalls = getNumberOfBallsForDeviceSize() - balls.length;
    const ballRadius = getBallRadiusForDeviceSize();
    for (let i = 0; i < numberOfBalls; i += 1) {
      balls.push(new Ball(randomPosition('X'), randomPosition('Y'), ballRadius));
    }
  };

  const modifyBallsForScreenSize = () => {
    const requiredNumberOfBalls = getNumberOfBallsForDeviceSize();
    if (balls.length !== requiredNumberOfBalls) {
      const ballRadius = getBallRadiusForDeviceSize();
      balls.forEach((ball) => {
        ball.setRadius(ballRadius)
        ball.setSpeed(randomSpeed());
      });
      if (balls.length < requiredNumberOfBalls) {
        createRequiredNumberOfBalls();
      } else {
        balls.splice(requiredNumberOfBalls - 1, balls.length - requiredNumberOfBalls);
      }
    }
  };

  const resizeCanvasToEnsureDrawingBufferDimensionsMatchDisplayDimensions = () => {
    const requiredWidth = canvas.clientWidth * DRAWING_TO_DISPLAY_SCALE_RATIO;
    const requiredHeight = canvas.clientHeight * DRAWING_TO_DISPLAY_SCALE_RATIO;
    if (canvas.width !== requiredWidth || canvas.height !== requiredHeight) {
      canvas.width = requiredWidth;
      canvas.height = requiredHeight;
    }
  };

  const animateFrame = (timestamp) => {
    const duration = timestamp - lastTimestamp;
    clearCanvas();
    canvasBackground();
    resizeCanvasToEnsureDrawingBufferDimensionsMatchDisplayDimensions();
    modifyBallsForScreenSize();
    balls.forEach((ball) => {
      ball.move(duration);
      ball.draw();
      balls.forEach(ball2 => ball.checkForBallOverlap(ball2));
      ball.checkForWallCollision();
      balls.forEach(ball2 => ball.checkForImpendingCollisionWithOtherBall(ball2));
    });
    lastTimestamp = timestamp;
    requestAnimationFrame(animateFrame);
  };

  resizeCanvasToEnsureDrawingBufferDimensionsMatchDisplayDimensions();
  createRequiredNumberOfBalls();
  animateFrame();
};
