import './sass/style.scss';
import {loadTicker} from './components/dataTicker';
import {loadHover} from './components/influencerHover';
import {loadProfileCard} from './components/influencerCard';
import {loadCarousel} from './components/influencerCarousel';
import {loadTeamMembers} from './components/teamMembers';
import {loadHomePageSlider} from './components/homepageSwiper';

export const activateTicker = (dataFeedTemplateParam, dataFeedURL) => {
  loadTicker(dataFeedTemplateParam, dataFeedURL);
};

export const activateHover = () => {
  loadHover();
};

export const activateInfluencerCard = () => {
  loadProfileCard();
};

export const activateInfluencerCarousel = () => {
  loadCarousel();
};

export const activateTeamMembers = (filePathToImages) => {
  loadTeamMembers(filePathToImages);
};

export const activateHomePageSlider = () => {
  loadHomePageSlider();
}
