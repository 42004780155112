import Swiper from 'swiper';
import {MOBILE_WIDTH_BREAKPOINT} from '../../constants';

export const loadHomePageSlider = () => {
  if (document.documentElement.clientWidth < MOBILE_WIDTH_BREAKPOINT) {
    const mySwiper = new Swiper('.steps', {
      slidesPerView: 'auto',
      spaceBetween: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      preloadImages: false,
      lazy: true,
      watchSlidesVisibility: true
    });
  }
};
