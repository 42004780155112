import {
  SPEED_IN_PIXELS_PER_MILLISECOND,
  KPIS,
  RESOURCES,
  CSS_ICON_TYPES,
  CSS_ARROWS,
  DIRECTIONS,
  FALLBACK_IMAGE,
  BACKUP_LIST
} from './constants';

// Define Global Variables
let items;
let dataFeedTemplate;
let offset = 0;
const displayItemsCount = 10;

const padWithZero = (number) => {
  if (number.toString().length === 1) {
    return `0${number}`;
  }
  return number.toString();
};

const formatKpi = (kpiValue, kpi) => {
  if ($.inArray(kpi, [KPIS.FOLLOWER_GROWTH,
    KPIS.ENGAGEMENT_RATE,
    KPIS.SPONSORED_CONTENT_ENGAGEMENT_RATE]) >= 0) {
    return `${(kpiValue * 100).toFixed(2)}%`;
  }
  if (kpi === KPIS.OVERPERFORMANCE) {
    return `${kpiValue.toFixed(2)}X`;
  }
  return kpiValue.toLocaleString('en');
};

const onImageError = (image) => {
  console.log('Loading image failed. Using fallback image.');
  if (image.src !== $(image).attr('data-fallback-image')) {
    image.src = $(image).attr('data-fallback-image');
  }
};

const transformItems = (itemsFromData, resource, total, kpi) => itemsFromData.map((item, index) => {
  const newItem = {};
  newItem.rank = padWithZero(index + 1);
  newItem.total = total;
  newItem.kpi_value = formatKpi(item[kpi], kpi);
  if (resource === RESOURCES.ACCOUNTS) {
    newItem.image_url = item.profile_picture_url;
    newItem.instagram_account = item.instagram_account;
    newItem.external_url = item.instagram_profile_url;
    newItem.fallback_image = FALLBACK_IMAGE;
  } else {
    newItem.image_url = item.image_thumb;
    newItem.instagram_account = item.owner_username;
    newItem.external_url = item.instagram_post_url;
    newItem.fallback_image = item.backup_image_url;
  }
  switch (kpi) {
    case KPIS.LIKES:
      newItem.icon_type = CSS_ICON_TYPES.LIKES;
      break;
    case KPIS.COMMENTS:
      newItem.icon_type = CSS_ICON_TYPES.COMMENTS;
      break;
    case KPIS.FOLLOWER_GROWTH:
      newItem.icon_type = CSS_ICON_TYPES.FOLLOWER_GROWTH;
      newItem.direction = item[kpi] > 0 ? CSS_ARROWS.UP : CSS_ARROWS.DOWN;
      break;
    case KPIS.ENGAGEMENT_RATE:
      newItem.icon_type = CSS_ICON_TYPES.LIKES;
      break;
    case KPIS.SPONSORED_CONTENT_ENGAGEMENT_RATE:
      newItem.icon_type = CSS_ICON_TYPES.LIKES;
      break;
    default:
      newItem.icon_type = null;
  }
  return newItem;
});

const getItemsFromData = (data) => {
  const { resource, total, kpi } = data.meta;
  if (resource === RESOURCES.ACCOUNTS) {
    return transformItems(data.accounts, resource, total, kpi);
  }
  return transformItems(data.posts, resource, total, kpi);
};

const repeatItemsToMeetRequiredLength = (uniqueItems) => {
  if (uniqueItems.length === 0) {
    throw new Error('The number of items must be greater than 0.');
  }
  let newItems = uniqueItems;
  while (newItems.length < displayItemsCount) {
    newItems = newItems.concat(uniqueItems);
  }
  return newItems;
};

const getTickerFinishPosition = (direction) => {
  if (direction === DIRECTIONS.LEFT) {
    const widthOfFirstListItem = $('.ss-data-feed-ticker-list > li:first').outerWidth();
    return -widthOfFirstListItem;
  }
  return 0;
};

const displayItemsInTicker = () => {
  let displayItems = items.slice(offset, displayItemsCount + offset);
  if (displayItemsCount + offset > items.length) {
    displayItems = items.slice(offset, items.length).concat(
      items.slice(0, displayItemsCount - (items.length - offset))
    );
  }
  const content = dataFeedTemplate.render(displayItems);
  $('.ss-data-feed-ticker-list').html(content);
};

const stopTickerAnimation = () => {
  $('.ss-data-feed-ticker-inner').stop(true);
};

const startTickerAnimation = (direction) => {
  const finishPosition = getTickerFinishPosition(direction);
  const distance = finishPosition - $('.ss-data-feed-ticker-inner').position().left;
  const duration = Math.abs(distance / SPEED_IN_PIXELS_PER_MILLISECOND);
  if (Number.isNaN(duration)) {
    throw new Error('The duration of the animation must be a number.');
  }
  $('.ss-data-feed-ticker-inner').animate({
    left: (`${finishPosition}`),
  }, duration, 'linear', () => {
    offset += 1;
    if (offset > items.length) {
      offset = 1;
    }
    stopTickerAnimation();
    displayItemsInTicker();
    startTickerAnimation(direction);
  });
};

const activatePauseOnHover = () => {
  $('.ss-data-feed-ticker-toggle').hover(() => {
    stopTickerAnimation();
  }, () => {
    startTickerAnimation(DIRECTIONS.LEFT);
  });
};

const loadDataIntoTicker = (data) => {
  let uniqueItems;
  if (data.meta.total === 0) {
    console.log('The list is empty. Using the backup list.');
    uniqueItems = getItemsFromData(BACKUP_LIST);
  } else {
    uniqueItems = getItemsFromData(data);
  }
  items = repeatItemsToMeetRequiredLength(uniqueItems);
  displayItemsInTicker();
  startTickerAnimation(DIRECTIONS.LEFT);
  activatePauseOnHover();
};

// Load the data feed from the Platform. Call this method externally.
export const loadTicker = (dataFeedTemplateParam, dataFeedURL) => {
  dataFeedTemplate = dataFeedTemplateParam;
  $.ajax({
    url: dataFeedURL,
    timeout: 2000,
  }).done((data) => {
    loadDataIntoTicker(data);
  }).fail(() => {
    console.log('Failed to load data from the API. Using the backup list.');
    loadDataIntoTicker(BACKUP_LIST);
  });
};
