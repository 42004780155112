import {nFormatter} from '../plugins/nFormatter';

export const loadHover = () => {
  $.fn.isInViewport = function isInViewport() {
    const elementTop = $(this).offset().top;
    const elementBottom = elementTop + $(this).outerHeight();
    const viewportTop = $(window).scrollTop();
    const viewportBottom = viewportTop + $(window).height();
    return (elementBottom > viewportTop && elementTop < viewportBottom);
  };

  function InfluencerTooltipComponent() {
    const target = $('[data-influencer-tooltip]');
    const tooltipContainer = '<ul class="ss-tooltips"></ul>';
    let targetX;
    let targetY
    let width;
    let targetTooltip;
    let tooltipWidth;
    let targetHandle;
    let windowWidth = $(window).width();
    let tooltipHtml;
    $('body').append(tooltipContainer);

    $(target).each(function createTooltips() {
      targetHandle = $(this).data('influencer-tooltip');

      tooltipHtml = `<div data-tooltip="${targetHandle}" class="ss-tooltip">`
                    + '<ul class="ss-tooltip-wrapper">'
                    + '<div class="ss-influencer-tooltip-thumbnail">'
                    + '<figure class="ss-card-image ss-ic-profile-pic"></figure>'
                    + '</div>'
                    + '<a href="" class="ss-influencer-handle"></a>'
                    + '<div class="tooltip-close"></div>'
                    + '<ul class="ss-influencer-tooltip-meta">'
                    + '<li>'
                    + '<h5 class="ss-eyebrow">Posts</h5>'
                    + '<p class="ss-numbers ss-ic-posts"></p>'
                    + '</li>'
                    + '<li>'
                    + '<h5 class="ss-eyebrow">Followers</h5>'
                    + '<p class="ss-numbers ss-ic-followers"></p>'
                    + '</li>'
                    + '<li>'
                    + '<h5 class="ss-eyebrow">Following</h5>'
                    + '<p class="ss-numbers ss-ic-following"></p>'
                    + '</li>'
                    + '</ul>'
                    + '</ul>'
                    + '</div>';
      $('.ss-tooltips').append(tooltipHtml);
    });

    function tooltipPosition() {
      $(target).each(function eachTarget() {
        if (matchMedia('(pointer:fine)').matches) {
          $(this).mouseover(function targetMouseover() {
            targetX = $(this).offset().left;
            targetY = $(this).offset().top;
            width = $(this).width();
            tooltipWidth = $('.ss-tooltip').width();
            targetTooltip = $('body').find(`[data-tooltip="${$(this).data('influencer-tooltip')}"]`);
            $(targetTooltip).show();
            setTimeout(() => { $(targetTooltip).addClass('is-open'); }, 10);
            $(targetTooltip).css('top', `${targetY}px`);
            if (targetX - tooltipWidth / 2 > 0 && targetX + tooltipWidth < windowWidth) {
              $(targetTooltip).css('left', `${targetX + (width / 2)}px`);
            } else if (targetX - tooltipWidth / 2 < 0) {
              $(targetTooltip).css('left', `${targetX + width}px`);
            } else if (targetX + tooltipWidth > windowWidth) {
              $(targetTooltip).css('left', `${targetX}px`);
            }
          });

          $(this).mouseleave(function targetMouseleave() {
            targetTooltip = $('body').find(`[data-tooltip="${$(this).data('influencer-tooltip')}"]`);
            $(targetTooltip).removeClass('is-open').hide();
            $(targetTooltip).mouseover(() => {
              $(targetTooltip).show().addClass('is-open');
            });
            $(targetTooltip).mouseleave(() => {
              $(targetTooltip).removeClass('is-open').hide();
            });
          });
        } else {
          $(this).click(function clickTooltipTouch() {
            pos = $(this).position();
            width = $(this).width();
            tooltipWidth = $('.ss-tooltip').width();
            targetTooltip = $('body').find(`[data-tooltip="${$(this).data('influencer-tooltip')}"]`);
            $(targetTooltip).show().addClass('is-touch');
            setTimeout(() => { $(targetTooltip).addClass('is-open'); }, 10);
            $(targetTooltip).css('top', `${pos.top}px`);
            $(targetTooltip).css('left', `${windowWidth / 2}px`);
          });
          $('.tooltip-close').click(function closeTooltipTouch() {
            $(this).parent().parent().removeClass('is-open')
              .hide();
          });
        }
      });
    }
    function getJsonTooltip() {
      $(target).each(function loopJson() {
        if ($(this).hasClass('done')) {
          // Do nothing
        } else if ($(this).isInViewport()) {
          $(this).addClass('done');

          targetHandle = $(this).data('influencer-tooltip');
          const targetElement = $('body').find(`[data-tooltip="${$(this).data('influencer-tooltip')}"]`);
          const profilePicture = $('.ss-ic-profile-pic');
          const handle = $('.ss-influencer-handle');
          const followers = $('.ss-ic-followers');
          const following = $('.ss-ic-following');
          const posts = $('.ss-ic-posts');

          $.getJSON(`https://app.socialstudies.io/api/v1/data_feed/influencers/${targetHandle}`, (data) => {
            const jData = data.influencer;
            const jPicture = jData.profile_picture_url;
            const jHandle = `<span>@</span>${jData.instagram_account}`;
            const jInstaUrl = jData.instagram_profile_url;
            const jFollowers = nFormatter(jData.followers_count, 1);
            const jFollowing = nFormatter(jData.following_count, 1);
            const jPosts = nFormatter(jData.post_count, 1);

            // Create Image and Append it
            function createImage() {
              const img = new Image();
              img.classList.add('image');
              targetElement.find(profilePicture).empty().append(img);
              img.setAttribute('src', jPicture);
            }
            createImage();

            // Influencer Handle
            targetElement.find(handle).empty().append(jHandle).attr('href', jInstaUrl)
              .removeClass('loading');

            // Check for Followers
            if (jFollowers != null) {
              targetElement.find(followers).empty().append(jFollowers);
            } else {
              targetElement.find(followers).parent().remove();
            }
            // Check for Following
            if (jFollowing != null) {
              targetElement.find(following).empty().append(jFollowing);
            } else {
              targetElement.find(following).parent().remove();
            }
            // Check for Posts
            if (jPosts != null) {
              targetElement.find(posts).empty().append(jPosts);
            } else {
              targetElement.find(posts).parent().remove();
            }
          }).fail(() => {
            targetElement.remove();
          });
        }
      });
    }

    $(window).on('load resize scroll', () => {
      $('[data-influencer-tooltip]').each(function loopTarget() {
        if ($(this).hasClass('done')) {
          // do nothing
        } else {
          getJsonTooltip();
        }
      });
    });
    $(window).on('load resize', () => {
      windowWidth = $(window).width();
      tooltipPosition();
    });
  }
  InfluencerTooltipComponent();
};
