export const loadCarousel = () => {
  $('.swiper-container').each(function(){
    _this = $(this);
    var nextArrow = $(this).parent().find('.swiper-button-next');
    var prevArrow = $(this).parent().find('.swiper-button-prev');


    new Swiper($(this), {
      slidesPerView: 'auto',
      spaceBetween: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: nextArrow,
        prevEl: prevArrow,
      },
      preloadImages: false,
      lazy: true,
      watchSlidesVisibility:true
    });

    //Get the total amount of slides in the carousel
    _this.find(".ss-total-slides").text(function (i, totalSlides) {
      var totalSlides = _this.find(".swiper-slide").length;
      // Add a ‘0’ before single digit numbers 
      console.log(totalSlides);
      totalSlides = +totalSlides;
      return (totalSlides < 9 ? "/0" : "/") + (totalSlides);
    });

_this.find(".ss-current-slide").text(function (i) {
      var currentSlide = 1 + i++;
      currentSlide = +currentSlide;
      // Add a ‘0’ before single digit numbers 
      return (currentSlide < 10 ? "0" : "") + (currentSlide);
    });
  //Calculate the current slide 
  _this.find(".swiper-slide").each(function() {
    
  });
});
}




