// Define Global Constants
export const SPEED_IN_PIXELS_PER_MILLISECOND = 0.07;
export const KPIS = {
  FOLLOWER_GROWTH: 'growth_rate',
  ENGAGEMENT_RATE: 'engagement_rate',
  SPONSORED_CONTENT_ENGAGEMENT_RATE: 'paid_engagement_rate',
  LIKES: 'likes_count',
  COMMENTS: 'comments_count',
  OVERPERFORMANCE: 'likes_delta_percent',
};
export const RESOURCES = {
  ACCOUNTS: 'accounts',
  POSTS: 'posts',
};
export const CSS_ICON_TYPES = {
  LIKES: 'likes',
  COMMENTS: 'comments',
  FOLLOWER_GROWTH: 'followers',
};
export const CSS_ARROWS = {
  UP: 'up',
  DOWN: 'down',
};
export const DIRECTIONS = { LEFT: 'left', RIGHT: 'right' };

// Fallback image in case of Instagram CDN failure
export const FALLBACK_IMAGE = 'https://gramlist-staging.herokuapp.com/images/user.png';

// Backup list in case of API failure
export const BACKUP_LIST = {
  accounts: [
    {
      id: 8879507,
      instagram_account: 'laurtheconnoisseur',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/da0589508d15124104dae00e3e16259c/5D74D840/'
        + 't51.2885-19/s150x150/56723987_2768289486576570_5208842963999784960_n.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/laurtheconnoisseur',
      growth_rate: 0.214697907251616,
      engagement_rate: 0.14512517664443142,
      paid_engagement_rate: 0.09354294141034522,
    },
    {
      id: 2847299,
      instagram_account: 'emmasweet',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/1833f6f5bf2c785c26d0ee35490111c5/5D52F8E8/'
        + 't51.2885-19/s150x150/20589730_146021909312732_8821436833947713536_a.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/_emmasweet',
      growth_rate: 0.183536825521859,
      engagement_rate: 0.13879056949972907,
      paid_engagement_rate: 0.1202455894537299
    },
    {
      id: 10080986,
      instagram_account: 'tastynybites',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/c53c27bdd6fee16d856ba1c8e3838f01/5D34456E/'
        + 't51.2885-19/s150x150/52363152_266659664251536_7832739919368814592_n.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/tastynybites',
      growth_rate: 0.181784660766962,
      engagement_rate: 0.03624400970559116,
      paid_engagement_rate: 0.01113735542201473
    },
    {
      id: 10080988,
      instagram_account: 'tastybitesnyc',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/37371f97f877d15f54505b7ea97844b9/5D3F24A2/'
        + 't51.2885-19/s150x150/56749593_994201037634870_5118011977716727808_n.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/tastybitesnyc',
      growth_rate: 0.104930046635576,
      engagement_rate: 0.16781683045932386,
      paid_engagement_rate: null
    },
    {
      id: 1682925,
      instagram_account: 'babeswhofeast',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/e11f684997bf33ed1bc38bed035d1763/5D40CD42/'
        + 't51.2885-19/s150x150/54731879_267916394151491_4306492127774244864_n.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/babeswhofeast',
      growth_rate: 0.090850927703135,
      engagement_rate: 0.11158871657103143,
      paid_engagement_rate: null
    },
    {
      id: 2925144,
      instagram_account: 'twotastebuddiez',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/f0bebf53a638c707d13e4adff25aee59/5D38CD2E/'
        + 't51.2885-19/s150x150/51110458_2070055933032292_146392542944952320_n.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/twotastebuddiez',
      growth_rate: 0.0891228971362527,
      engagement_rate: 0.06910256423795341,
      paid_engagement_rate: 0.07603137163707235,
    },
    {
      id: 2925150,
      instagram_account: 'kushfood',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/1ffa68c0806e86a0cc1c3eb4ab8c13c2/5D45A36C/'
        + 't51.2885-19/s150x150/43818255_180188879573579_5298012948990525440_n.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/kushfood',
      growth_rate: 0.084975459673284,
      engagement_rate: 0.018296057109321855,
      paid_engagement_rate: 0.01529885107901875,
    },
    {
      id: 10080994,
      instagram_account: 'flnyfoodcentral',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/610599209e38b08da5ed196545ac22c4/5D567CCA/'
        + 't51.2885-19/s150x150/17126179_785332181622462_3065849279105990656_a.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/flnyfoodcentral',
      growth_rate: 0.0838343809784094,
      engagement_rate: 0.05633042337131012,
      paid_engagement_rate: null,
    }, {
      id: 2925151,
      instagram_account: 'austineater',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/4c3aaae160c158b5bc36c4ad2bbdb29a/5D72BE0C/'
        + 't51.2885-19/s150x150/33559689_1838511076209524_8164341772923699200_n.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/austineater',
      growth_rate: 0.0824856411321016,
      engagement_rate: 0.018937167476467568,
      paid_engagement_rate: null,
    }, {
      id: 10080989,
      instagram_account: 'thisgirleatsny',
      profile_picture_url: 'https://scontent-dfw5-1.cdninstagram.com/vp/59e8563df08b11883e69a188545ef13a/5D76283B/'
        + 't51.2885-19/s150x150/39011799_1070707886430286_6338996065286488064_n.jpg?_nc_ht='
        + 'scontent-dfw5-1.cdninstagram.com',
      instagram_profile_url: 'https://www.instagram.com/thisgirleatsny',
      growth_rate: 0.0790475419130153,
      engagement_rate: 0.032740704983796734,
      paid_engagement_rate: 0.033449228702197166,
    }],
  meta: {
    total: 10,
    kpi: 'growth_rate',
    resource: 'accounts',
  },
};
